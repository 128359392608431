import { Transition } from "@headlessui/react";
import * as React from "react";

import Logo from "../images/logo-white.png";

// markup
const IndexPage = () => {
  return (
    <main className="h-screen flex items-center px-6 lg:px-32 bg-hero-img bg-cover text-white">
      <section className="w-full md:w-9/12 xl:w-8/12">
        <Transition
          appear={true}
          show={true}
          enter="transition-opacity transition duration-1000 delay-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <div>
            <img className="h-16 w-auto pb-3" src={Logo} />
            <span className="font-bold uppercase tracking-widest">
              Liuli Studio
            </span>
            <h1 className="text-3xl lg:text-5xl font-bold pb-2">
              <span className="font-medium">Make </span>
              <span className="font-bold">Things </span>
              <span className="font-medium">Happen</span>
            </h1>
            <h1 className="text-base lg:text-xl font-bold ">
              <span className="font-medium">ぞうぶつちゅうれい</span>
            </h1>
            <h1 className="text-3xl lg:text-5xl font-bold">
              <span className="font-medium">造物鑄靈</span>
            </h1>
            {/* <p class="font-bold mb-1">The Design is in the des...</p> */}
            <p className="text-sm pt-4 ">
              Copyright © 2022 Liuli Studio. All Rights Reserved.
            </p>

            <div className="flex flex-row decoration-2 underline-offset-4 space-x-4 font-bold underline pt-10   ">
              <div className="hover:text-orange-500 hover:underline hover:decoration-4 ">
                <a
                  href="https://www.instagram.com/liulistudio/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </div>
              <div className="hover:text-orange-500 hover:underline hover:decoration-4 ">
                <a
                  href="https://twitter.com/liulistudio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </div>
              <div className="hover:text-orange-500 hover:underline hover:decoration-4">
                <a
                  href="https://opensea.io/LiuliStudio?tab=created"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OpenSea
                </a>
              </div>
            </div>
          </div>
        </Transition>
      </section>
    </main>
  );
};

export default IndexPage;
